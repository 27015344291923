import { Action, Command } from "Apps/TerminalApp/App";
import { Terminal, styles } from "Apps/TerminalApp/Terminal";

const action: Action = (terminal: Terminal, args?: string[]) => {
  let result = "";

  if (args?.includes("--help") || args?.includes("-h")) {
    terminal.push(
      <div>
        Usage: base64 [options] [text]
        <br />
        <br />
        Options:
        <br />
        &nbsp;&nbsp;--decode, -d &nbsp;&nbsp;&nbsp;&nbsp;Decode a base64 encoded
        string
      </div>
    );
    return;
  }

  let text = args?.find((arg) => arg.startsWith('"') && arg.endsWith('"'));

  if (!text) {
    terminal.push(
      <div className={styles.error}>
        Invalid arguments
        <br />
        Try
        <button onClick={() => terminal?.runCommand("base64 --help")}>
          'base64 --help'
        </button>
        for more information.
      </div>
    );
    return;
  }

  text = text.slice(1, -1);

  if (args?.includes("--decode") || args?.includes("-d")) {
    try {
      result = atob(text);
    } catch (e) {
      terminal.printError((e as Error).message);
    }
  } else {
    result = btoa(text);
  }

  terminal.push(<span>{result}</span>);
};

export const base64Command: Command = {
  description: "base64 encode/decode a string",
  action,
};
