export class LinkedListItem {
  value: any;
  next?: LinkedListItem;
  prev?: LinkedListItem;

  constructor(value: string) {
    this.value = value;
  }
}

export class LRU {
  private _head: LinkedListItem;
  private _tail?: LinkedListItem;
  private _map: Map<string, LinkedListItem> = new Map();
  private _size: number;

  constructor(size: number = 999) {
    const dummyHead = new LinkedListItem("");

    this._head = dummyHead;

    this._size = size;
  }

  add(value: string) {
    const item = this._map.get(value);
    if (item) {
      if (item.prev) {
        item.prev.next = item.next;
      }

      if (item.next) {
        item.next.prev = item.prev;
      } else {
        this._tail = item.prev;
      }

      if (this._head.next) {
        item.next = this._head.next;
        this._head.next.prev = item;
      }

      item.prev = this._head;
      this._head.next = item;
    } else {
      const item = new LinkedListItem(value);

      if (!this._tail) {
        this._tail = item;
      }

      if (this._head.next) {
        this._head.next.prev = item;
        item.next = this._head.next;
      }

      item.prev = this._head;
      this._head.next = item;

      this._map.set(value, item);

      if (this._map.size > this._size) {
        this._map.delete(this._tail?.value);

        this._tail = this._tail?.prev;

        if (this._tail?.next) {
          this._tail.next.prev = undefined;
        }

        if (this._tail) {
          this._tail.next = undefined;
        }
      }
    }
  }

  get head() {
    return this._head;
  }

  get tail() {
    return this._tail;
  }
}
