import { Action, Command } from "Apps/TerminalApp/App";
import { Terminal } from "Apps/TerminalApp/Terminal";
import css from "../../TerminalApp.module.scss";

interface LoginRequest {
  username?: string;
  password?: string;
}

const action: Action = async (terminal: Terminal) => {
  const isLoggedIn = await terminal.call<undefined, string>(
    "/auth/check",
    "GET"
  );

  if (isLoggedIn === "true") {
    terminal.push(<span>already logged in!</span>);
    return;
  }

  let username = "";
  do {
    username = await terminal.readLine("username");
  } while (!username.trim().length);

  let password = "";
  do {
    password = await terminal.readLine("password", true);
  } while (!password.trim().length);

  try {
    await terminal.call<LoginRequest>("/auth/login", "POST", {
      username,
      password,
    });
    terminal.push(<span>login successful!</span>);
  } catch (error) {
    terminal.push(<div className={css.error}>{(error as Error).message}</div>);
  }
};

export const loginCommand: Command = {
  action,
  description: "login to your account",
};
