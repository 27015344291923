import { LRU, LinkedListItem } from "Utils/LRU";
import { App } from "./App";
import css from "./TerminalApp.module.scss";

export class Terminal {
  private _history: JSX.Element[] = [];
  private _inputKey: string | undefined;
  private _resolve: ((value: string) => void) | undefined;
  private _render?: () => void;
  private _activeApp: App;
  private _defaultApp: App;
  private _apps: Map<string, App> = new Map();
  private _interrupted: boolean = false;
  private _hideInput: boolean = false;
  private _isBusy: boolean = false;
  private _commandHistory: LRU = new LRU();
  private _focusedCommand?: LinkedListItem;

  constructor(apps: Map<string, App>) {
    this._apps = apps;
    this._apps.forEach((app) => {
      app.terminal = this;
    });
    this._defaultApp = this._apps.values().next().value;
    this._activeApp = this._defaultApp;
  }

  public push(item: JSX.Element) {
    this._history.push(item);
    this.render();
  }

  public printError(message: string) {
    this._history.push(
      <div className={styles.error}>{message}</div>
    );
  }

  get history(): JSX.Element[] {
    return this._history;
  }

  public render() {
    this._render?.();
  }

  public setRender(_render: () => void) {
    this._render = _render;
  }

  public clear() {
    this._history = [];
  }

  set activeApp(app: App) {
    this._activeApp = app;
  }

  get activeApp(): App {
    return this._activeApp;
  }

  public activateDefaultApp() {
    this.activeApp = this._defaultApp;
  }

  public interrupt() {
    this._interrupted = true;
    this.setNotBusy();
    this.push(<div className={css.error}>^C</div>);
    this.activeApp?.setPrompt();
    this._hideInput = false;
  }

  public readLine(key: string, isSecret: boolean = false): Promise<string> {
    this._activeApp?.setPrompt(`${key}:`);
    this._inputKey = key;
    this._hideInput = isSecret;

    return new Promise((resolve) => {
      this._resolve = resolve;
    });
  }

  public runCommand(command: string, isCommandVisible: boolean = true) {
    if (isCommandVisible) {
      this.printCommand(command);
    }

    this.processInput(command);
  }

  public shouldHideInput() {
    return this._hideInput;
  }

  public setNotBusy() {
    if (!this._isBusy) return;

    this._history.pop();
    this._isBusy = false;
    this.render();
  }

  public setBusy() {
    this._isBusy = true;
    this.push(<div className={css.loading}></div>);
  }

  public isBusy() {
    return this._isBusy;
  }

  public async call<TRequest, TResponse = undefined>(
    url: string,
    method: "POST" | "GET",
    body?: TRequest
  ): Promise<TResponse | undefined> {
    this.setBusy();

    if (url.startsWith("/")) {
      url = `${process.env.REACT_APP_API_BASE_URL}${url}`;
    }

    const response = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      credentials: "include",
    });

    if (!response.ok) {
      this.setNotBusy();
      const error = await response.text();
      throw new Error(error);
    }

    const data = await response.text();
    this.setNotBusy();
    return data as TResponse;
  }

  public getPreviousCommand() {
    if (this._focusedCommand?.next) {
      this._focusedCommand = this._focusedCommand?.next;
    }

    return this._focusedCommand?.value;
  }

  public getNextCommand() {
    if (this._focusedCommand?.prev) {
      this._focusedCommand = this._focusedCommand?.prev;
    }

    return this._focusedCommand?.value;
  }

  public resetFocusedCommand() {
    this._focusedCommand = this._commandHistory.head;
  }

  public isInputMode() {
    return !!this._inputKey;
  }

  private processInput(input: string) {
    if (this._interrupted) {
      this.reset();
    }

    if (!this._inputKey?.length) {
      if (input) {
        this._commandHistory.add(input);
        this.resetFocusedCommand();

        const parts = input.match(/"[^"]+"|\S+/g) || [];
        const [commandName, ...args] = parts;
        const command = this._activeApp?.command(commandName || "");

        if (command) {
          command(this, args);
        } else {
          this.push(
            <div className={css.error}>{input}: command not found</div>
          );
        }
      }
    } else {
      this._activeApp?.set(this._inputKey, input);
      this._resolve?.(input);
      this.reset();
    }
  }

  private reset() {
    this._inputKey = undefined;
    this._resolve = undefined;
    this._interrupted = false;
    this._hideInput = false;
    this._activeApp?.setPrompt();
    this.setNotBusy();
  }

  private printCommand(command: string) {
    this.push(
      <div>
        <span className={css.prompt}>{this._activeApp?.prompt}</span>
        <span className={css.command}>
          {this._hideInput && command.length ? "*****" : command}
        </span>
      </div>
    );
  }
}

export const styles = {
  error: css.error,
};
