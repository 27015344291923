const formatCurrency = (x: number) => {
  return "$" + x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const formatDays = (days: number) => {
  days = Math.abs(Math.ceil(days));
  const monthDays = 365 / 12;
  const years = Math.floor(days / 365);
  const months = Math.floor((days % 365) / monthDays);
  const remainingDays = Math.floor((days % 365) % monthDays);

  let result = "";
  if (years > 0) {
    result += `${years} ${years === 1 ? "year" : "years"}`;
  }
  if (months > 0) {
    if (result !== "") result += ", ";
    result += `${months} ${months === 1 ? "month" : "months"}`;
  }
  if (remainingDays > 0) {
    if (result !== "") result += ", ";
    result += `${remainingDays} ${remainingDays === 1 ? "day" : "days"}`;
  }

  return result;
};

const NPER = (
  rate: number,
  payment: number,
  present: number,
  future: number = 0,
  type: number = 0
) => {
  const num = payment * (1 + rate * type) - future * rate;
  const den = present * rate + payment * (1 + rate * type);

  return Math.log(num / den) / Math.log(1 + rate);
};

export const utils = {
  formatCurrency,
  formatDays,
  NPER,
};
