import { Action, Command } from "Apps/TerminalApp/App";
import { Terminal } from "Apps/TerminalApp/Terminal";

const action: Action = (terminal: Terminal) => {
  interface Item {
    text: string;
    link: string;
  }

  const items: Item[] = [
    { text: "notes◥", link: "/notes" },
    { text: "linkedin◥", link: "https://au.linkedin.com/in/soheilvaseghi" },
    { text: "github◥", link: "https://github.com/vaseghi" },
  ];

  const getFormattedItem = (path: string, text: string) => {
    return (
      <p key={text}>
        &nbsp;*&nbsp;
        <a href={path} rel="noreferrer" target="_blank">
          {text}
        </a>
      </p>
    );
  };

  terminal.push(
    <>
      <p>Welcome to my website!</p>
      <br />

      {items.map((item) => getFormattedItem(item.link, item.text))}

      <br />
      <p>
        Type
        <button
          onClick={() => {
            terminal.runCommand("help");
          }}
        >
          'help'
        </button>
        to see available commands.
      </p>
    </>
  );
};

export const welcomeCommand: Command = {
  description: "welcome message",
  aliases: ["hi", "hello"],
  action,
};
