import {
  Button,
  ButtonGroup,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import css from "./LoanCalculatorApp.module.scss";
import { ChangeEvent, useRef, useState } from "react";
import { CurrencyInput, PercentInput, YearsInput } from "Components";
import cx from "classnames";
import { utils } from "Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import classNames from "classnames";
import { LoanCalculator } from "./LoanCalculator";

export const LoanCalculatorApp = () => {
  const [_, setRender] = useState(false);
  const [interestMode, setInterestMode] = useState(0);

  const defaultItem = new LoanCalculator({
    loanAmount: 1000000,
    interestRate: 6,
    loanYears: 30,
    offsetBalance: 0,
    paymentFrequencyPerYear: 12,
  });

  const items = useRef<LoanCalculator[]>([defaultItem]);

  const render = () => {
    setRender((old) => !old);
  };

  const loanAmountChangeHandler =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      items.current[index].update({
        loanAmount: parseInt(event.target.value, 10),
      });
      render();
    };

  const interestRateChangeHandler =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      items.current[index].update({
        interestRate: parseFloat(event.target.value),
      });
      render();
    };

  const loanYearsChangeHandler =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      items.current[index].update({
        loanYears: parseInt(event.target.value, 10),
      });
      render();
    };

  const paymentFrequencyPerYearChangeHandler =
    (index: number, value: number) => () => {
      items.current[index].update({ paymentFrequencyPerYear: value });
      render();
    };

  const offsetBalanceChangeHandler =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      items.current[index].update({
        offsetBalance: parseInt(event.target.value, 10),
      });
      render();
    };

  const extraPaymentChangeHandler =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      items.current[index].update({
        extraPayment: parseInt(event.target.value, 10),
      });
      render();
    };

  const getDiffElement = (
    value1: number,
    value2: number,
    format: (value: number) => string
  ) => {
    const hidden = <div className={cx(css.diff, css.hidden)}>0</div>;

    if (
      value1 !== undefined &&
      value2 !== undefined &&
      value1 !== value2 &&
      Math.abs(value1 - value2) > 0.009
    ) {
      const value = value1 - value2;

      return (
        <div className={cx(css.diff, { [css.positive]: value > 0 })}>
          {format(value)}
        </div>
      );
    }

    return hidden;
  };

  const currencyDiffFormatter = (value: number) => {
    const formattedValue = utils.formatCurrency(value);
    return value > 0 ? `+${formattedValue}` : formattedValue;
  };

  const getRepaymentDiffElement = (index: number) => {
    const current = items.current[index].repayment;
    const base = items.current[0].repayment;

    return getDiffElement(current, base, currencyDiffFormatter);
  };

  const getInterestDiffElement = (index: number) => {
    const current = getInterest(items.current[index]);
    const base = getInterest(items.current[0]);

    return getDiffElement(current, base, currencyDiffFormatter);
  };

  const getPayOffDiffElement = (index: number) => {
    const current = items.current[index].payOffDays;
    const base = items.current[0].payOffDays;

    return getDiffElement(current, base, utils.formatDays);
  };

  const deleteButtonHandler = (index: number) => () => {
    if (items.current.length === 1) return;

    items.current.splice(index, 1);
    render();
  };

  const newItemClickHandler = (index: number) => () => {
    const copyItem = items.current[index];
    const newItem = new LoanCalculator({
      loanAmount: copyItem.loanAmount,
      interestRate: copyItem.interestRate,
      loanYears: copyItem.loanYears,
      offsetBalance: copyItem.offsetBalance,
      paymentFrequencyPerYear: copyItem.paymentFrequencyPerYear,
      extraPayment: copyItem.extraPayment,
    });

    items.current.splice(index + 1, 0, newItem);

    render();
  };

  const interestModeChangeHandler = () => {
    setInterestMode((old) => {
      if (old === 2) {
        return 0;
      }

      return old + 1;
    });
  };

  const getInterest = (item: LoanCalculator) => {
    if (interestMode === 0) {
      return item.firstInterest;
    } else if (interestMode === 1) {
      return item.averageInterest;
    } else {
      return item.totalInterest;
    }
  };

  const getInterestLabel = () => {
    if (interestMode === 0) {
      return "First interest";
    } else if (interestMode === 1) {
      return "Average interest";
    } else {
      return "Total interest";
    }
  };

  const getCalculatorInstance = (item: LoanCalculator, index: number) => {
    return (
      <div key={index} className={css.item}>
        <div className={css.inputs}>
          <div className={css.header}>
            <input
              type="text"
              defaultValue={index === 0 ? `Current` : `Option ${index}`}
              className={css.title}
            ></input>

            <IconButton
              aria-label="delete"
              size="small"
              className={classNames(css.deleteButton, {
                [css.hide]: index === 0,
              })}
              onClick={deleteButtonHandler(index)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>

            <IconButton
              aria-label="add"
              size="small"
              className={css.addButton}
              onClick={newItemClickHandler(index)}
            >
              <AddCircleOutlineIcon fontSize="inherit" />
            </IconButton>
          </div>
          <TextField
            label="Amount borrowed"
            InputProps={{
              inputComponent: CurrencyInput,
            }}
            value={item.loanAmount}
            onChange={loanAmountChangeHandler(index)}
          />

          <div className={css.row}>
            <TextField
              label="َInterest rate"
              InputProps={{
                inputComponent: PercentInput,
              }}
              value={item.interestRate}
              onChange={interestRateChangeHandler(index)}
            />

            <TextField
              label="َLength of loan"
              InputProps={{
                inputComponent: YearsInput,
              }}
              value={item.loanYears}
              onChange={loanYearsChangeHandler(index)}
            />
          </div>

          <div className={css.row}>
            <TextField
              label="Offset"
              InputProps={{
                inputComponent: CurrencyInput,
              }}
              value={item.offsetBalance}
              onChange={offsetBalanceChangeHandler(index)}
            />

            <TextField
              label="Extra payment"
              InputProps={{
                inputComponent: CurrencyInput,
              }}
              value={item.extraPayment}
              onChange={extraPaymentChangeHandler(index)}
            />
          </div>

          <ButtonGroup
            disableElevation
            color="primary"
            className={css.repaymentFrequencyButtonGroup}
          >
            <Button
              variant={
                item.paymentFrequencyPerYear === 52 ? "contained" : "outlined"
              }
              onClick={paymentFrequencyPerYearChangeHandler(index, 52)}
            >
              Weekly
            </Button>
            <Button
              variant={
                item.paymentFrequencyPerYear === 26 ? "contained" : "outlined"
              }
              onClick={paymentFrequencyPerYearChangeHandler(index, 26)}
            >
              Fortnightly
            </Button>
            <Button
              variant={
                item.paymentFrequencyPerYear === 12 ? "contained" : "outlined"
              }
              onClick={paymentFrequencyPerYearChangeHandler(index, 12)}
            >
              Monthly
            </Button>
          </ButtonGroup>
        </div>

        <div className={css.results}>
          <div className={css.row}>
            <div className={css.resultItem}>
              <InputLabel>Repayment</InputLabel>
              <div className={css.amount}>
                {utils.formatCurrency(item.repayment)}
              </div>
              {getRepaymentDiffElement(index)}
            </div>

            <div className={css.resultItem}>
              <button onClick={interestModeChangeHandler}>
                <InputLabel>{getInterestLabel()}</InputLabel>
              </button>
              <div className={css.amount}>
                {utils.formatCurrency(getInterest(item))}
              </div>
              {getInterestDiffElement(index)}
            </div>
          </div>

          <div className={css.row}>
            <div className={css.resultItem}>
              <InputLabel>Pay off</InputLabel>
              <div className={css.amount}>
                {utils.formatDays(Math.round(item.payOffDays))}
              </div>
              {getPayOffDiffElement(index)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={css.component}>
      {items.current.map((item, index) => {
        return getCalculatorInstance(item, index);
      })}
    </div>
  );
};
