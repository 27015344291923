import { Terminal } from "./Terminal";

export type Action = (terminal: Terminal, args?: string[]) => void;
export interface Command {
  action: Action;
  aliases?: string[];
  description?: string;
  excludeFromHelp?: boolean;
}
export class App {
  private _name: string = "";
  private _prompt: string = "";
  private _defaultPrompt: string = "";
  private _terminal?: Terminal;
  private _commands: Map<string, Command> = new Map();
  private _aliases: Map<string, string> = new Map();
  private _storage: Map<string, string> = new Map();
  private _appCommandsFormatted: JSX.Element[] = [];

  constructor(name: string, prompt: string, commands: Map<string, Command>) {
    this._name = name;
    this._defaultPrompt = prompt;
    this._prompt = prompt;
    this._commands = commands;

    this._commands.set("exit", {
      aliases: ["quit"],
      excludeFromHelp: true,
      action: (terminal: Terminal) => {
        this._storage.clear();
        terminal.activateDefaultApp();
      },
    });

    this._commands.set("clear", {
      aliases: ["cls"],
      excludeFromHelp: true,
      action: (terminal: Terminal) => {
        terminal.clear();
      },
    });

    this._commands.set("help", {
      description: "show available commands",
      aliases: ["?", "man"],
      action: (terminal: Terminal) => {
        terminal.push(<>{this._appCommandsFormatted}</>);
      },
    });

    const appCommands = Array.from(this._commands.entries())
      .filter(([key, command]) => !command.excludeFromHelp)
      .map(([key, command]) => [key, command.description || ""]);

    this._appCommandsFormatted = this.getCommandList(appCommands);

    Array.from(this._commands.entries()).forEach(([key, command]) => {
      if (command.aliases) {
        command.aliases.forEach((alias) => {
          this._aliases.set(alias, key);
        });
      }
    });
  }

  get terminal(): Terminal | undefined {
    return this._terminal;
  }

  set terminal(terminal: Terminal | undefined) {
    this._terminal = terminal;
  }

  get name(): string {
    return this._name;
  }

  get prompt(): string {
    return this._prompt;
  }

  set prompt(prompt: string) {
    this._prompt = prompt;
  }

  get commands(): Command[] {
    return Array.from(this._commands.values());
  }

  public command(name: string) {
    return (
      this._commands.get(name)?.action ||
      this._commands.get(this._aliases.get(name) || "")?.action
    );
  }

  public set(key: string, value: string) {
    this._storage.set(key, value);
  }

  public get(key: string) {
    return this._storage.get(key);
  }

  public setPrompt(prompt?: string) {
    if (prompt && prompt.length > 0) {
      this._prompt = prompt;
    } else {
      this._prompt = this._defaultPrompt;
    }

    this._terminal?.render();
  }

  private getCommandList = (items: string[][], gap: number = 4) => {
    const maxLength = items.reduce(
      (max, item) => Math.max(max, item[0].length),
      0
    );

    return items.map((item) => {
      const spaces = "&nbsp;".repeat(maxLength - item[0].length + gap);
      return (
        <div key={item[0]}>
          <span>
            &nbsp;*
            <button
              onClick={() => {
                this._terminal?.runCommand(item[0]);
              }}
            >
              {item[0]}
            </button>
            <span
              dangerouslySetInnerHTML={{
                __html: spaces,
              }}
            />
          </span>
          <span>{item[1]}</span>
        </div>
      );
    });
  };
}
