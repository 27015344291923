import React from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

interface CustomInputOption {
  prefix?: string;
  suffix?: string;
  thousandSeparator?: boolean;
}

const CustomInput = (options: CustomInputOption) =>
  React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={options.thousandSeparator}
        valueIsNumericString
        prefix={options.prefix}
        suffix={options.suffix}
      />
    );
  });

export const CurrencyInput = CustomInput({
  prefix: "$",
  thousandSeparator: true,
}) as any;

export const PercentInput = CustomInput({ suffix: "%" }) as any;

export const YearsInput = CustomInput({ suffix: " years" }) as any;
