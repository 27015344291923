import { useEffect, useRef, useState } from "react";
import css from "./TerminalApp.module.scss";
import { shellApp } from "./Apps";
import { Terminal } from "./Terminal";
import { App } from "./App";
import classNames from "classnames";

const terminal = new Terminal(new Map<string, App>([["shell", shellApp]]));

export const TerminalApp = () => {
  const [inputValue, setInputValue] = useState("");
  const [_, setRender] = useState(0);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const render = () => {
      setRender((render) => render + 1);
    };

    terminal.setRender(render);
    terminal.runCommand("hi");
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === "c") {
        terminal.interrupt();
        setInputValue("");
      }

      if (event.metaKey || event.ctrlKey || event.altKey) return;

      if (terminal.isBusy()) return;

      if (!terminal.isInputMode()) {
        if (event.key === "ArrowUp") {
          setInputValue(terminal.getPreviousCommand());
          event.preventDefault();
        }

        if (event.key === "ArrowDown") {
          setInputValue(terminal.getNextCommand());
          event.preventDefault();
        }
      }

      if (event.key === "Enter") {
        event.preventDefault();

        if (inputValue === "^c") {
          terminal.interrupt();
          setInputValue("");
          return;
        }

        terminal.runCommand(inputValue);
        setInputValue("");
      }

      if (terminal.shouldHideInput()) {
        passwordInputRef.current?.focus();
      } else {
        textareaRef.current?.focus();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [inputValue]);

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    if (terminal.isBusy()) return;

    setInputValue(event.target.value);
    terminal.resetFocusedCommand();
  };

  return (
    <div className={css.terminalApp}>
      {terminal.history.map((item, index) => (
        <div key={index} className={css.result}>
          {item}
        </div>
      ))}

      <div
        className={classNames(css.inputContainer, {
          [css.hidden]: terminal.isBusy(),
        })}
      >
        <span className={css.prompt}>{terminal.activeApp?.prompt}</span>
        {terminal.shouldHideInput() ? (
          <input
            autoFocus
            autoCapitalize="none"
            type="password"
            value={inputValue}
            ref={passwordInputRef}
            onChange={handleChange}
            style={{
              marginLeft: (terminal.activeApp?.prompt.length || 0) + 1 + "ch",
            }}
          />
        ) : (
          <textarea
            autoFocus
            autoCapitalize="none"
            value={inputValue}
            ref={textareaRef}
            onChange={handleChange}
            style={{
              textIndent: (terminal.activeApp?.prompt.length || 0) + 1 + "ch",
            }}
          />
        )}
      </div>
    </div>
  );
};
