import { Action, Command } from "Apps/TerminalApp/App";
import { Terminal } from "Apps/TerminalApp/Terminal";
import css from "../../TerminalApp.module.scss";

const action: Action = async (terminal: Terminal) => {
  const isLoggedIn = await terminal.call<undefined, string>(
    "/auth/check",
    "GET"
  );

  if (isLoggedIn === "false") {
    terminal.push(<span>already logged out!</span>);
    return;
  }

  try {
    await terminal.call("/auth/logout", "POST");
    terminal.push(<span>logout successful!</span>);
  } catch (error) {
    terminal.push(<div className={css.error}>{(error as Error).message}</div>);
  }
};

export const logoutCommand: Command = {
  action,
  description: "Logout of your account",
};
