import { App, Command } from "../../App";
import { base64Command } from "./Base64";
import { loginCommand } from "./Login";
import { logoutCommand } from "./Logout";
import { welcomeCommand } from "./Welcome";

const commands = new Map<string, Command>([
  ["login", loginCommand],
  ["welcome", welcomeCommand],
  ["logout", logoutCommand],
  ["base64", base64Command],
]);

export const shellApp = new App("shell", "$", commands);
