import { utils } from "Utils";

interface LoanCalculatorData {
  loanAmount?: number;
  interestRate?: number;
  loanYears?: number;
  offsetBalance?: number;
  extraPayment?: number;
  paymentFrequencyPerYear?: number;
}

export class LoanCalculator {
  private _loanAmount: number = 0;
  private _interestRate: number = 0;
  private _loanYears: number = 0;
  private _offsetBalance: number = 0;
  private _extraPayment: number = 0;
  private _paymentFrequencyPerYear: number = 12;

  private _periodDays: number = 0;
  private repaymentPerDay: number = 0;

  constructor(data: LoanCalculatorData) {
    this.update(data);
  }

  get loanAmount(): number {
    return this._loanAmount;
  }

  get interestRate(): number {
    return this._interestRate;
  }

  get loanYears(): number {
    return this._loanYears;
  }

  get offsetBalance(): number {
    return this._offsetBalance;
  }

  get extraPayment(): number {
    return this._extraPayment;
  }

  get paymentFrequencyPerYear(): number {
    return this._paymentFrequencyPerYear;
  }

  get repayment(): number {
    return this.repaymentPerDay * this._periodDays + this._extraPayment;
  }

  get firstInterest(): number {
    const dailyRate = this._interestRate / 100 / 365;
    const balance = this._loanAmount - this._offsetBalance;

    return balance * dailyRate * this._periodDays;
  }

  get totalInterest(): number {
    const days = this.payOffDays;
    const extraPaymentPerDay = this._extraPayment / this._periodDays;
    const totalPaymentPerDay = this.calcRepaymentPerDay() + extraPaymentPerDay;
    const totalRepayment = totalPaymentPerDay * days;

    return totalRepayment - this._loanAmount + this._offsetBalance;
  }

  get averageInterest(): number {
    const interestPerDay = this.totalInterest / this.payOffDays;

    return interestPerDay * this._periodDays;
  }

  get payOffDays(): number {
    const rate = this._interestRate / 100 / this._paymentFrequencyPerYear;
    const repayment = this.repayment;
    const presentValue = -1 * (this._loanAmount - this._offsetBalance);

    var periods = utils.NPER(rate, repayment, presentValue);

    return periods * this._periodDays;
  }

  update(data: LoanCalculatorData) {
    if (data.loanAmount !== undefined) {
      this._loanAmount = data.loanAmount;
    }

    if (data.interestRate !== undefined) {
      this._interestRate = data.interestRate;
    }

    if (data.loanYears !== undefined) {
      this._loanYears = data.loanYears;
    }

    if (data.offsetBalance !== undefined) {
      this._offsetBalance = data.offsetBalance || 0;
    }

    if (data.extraPayment !== undefined) {
      this._extraPayment = data.extraPayment || 0;
    }

    if (data.paymentFrequencyPerYear !== undefined) {
      this._paymentFrequencyPerYear = data.paymentFrequencyPerYear;
      this._periodDays = 365 / this._paymentFrequencyPerYear;
    }

    this.repaymentPerDay = this.calcRepaymentPerDay();
  }

  private calcRepaymentPerDay = (useOffset: boolean = false): number => {
    const ratePerPeriod =
      this._interestRate / 100 / this._paymentFrequencyPerYear;
    const numberOfPeriods = this._loanYears * this._paymentFrequencyPerYear;

    const amount = useOffset
      ? this._loanAmount - this.offsetBalance
      : this._loanAmount;

    const repayment =
      (amount *
        (ratePerPeriod * Math.pow(1 + ratePerPeriod, numberOfPeriods))) /
      (Math.pow(1 + ratePerPeriod, numberOfPeriods) - 1);

    return (repayment * this._paymentFrequencyPerYear) / 365;
  };
}
